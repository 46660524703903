import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'Main',
        component: () => import('../view/Main'),
        children: [
            // {
            //     path:'/home',
            //     name:'HomePage',
            //     component:()=>import('../view/home/index')
            // },
            {
                path:'/picture-works',
                name:'PictureWorks',
                meta: {
                    title: '发文统计'
                },
                component:()=>import('../view/pictureWorks/index')
            },
            {
                path:'/mp-user',
                name:'MpUser',
                meta: {
                    title: '小程序用户'
                },
                component:()=>import('../view/mpUser/index')
            },
            {
                path:'/sys-user',
                name:'SysUser',
                meta: {
                  title: '系统用户'
                },
                component:()=>import('../view/sysUser/index')
            }
        ]
    },  {
        path:'/login',
        name:'LoginPage',
        component:()=>import('../view/login/index')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

//挂载路由导航守卫
router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        return next()
    }

    // 获取token
    const token = localStorage.getItem('token');
    if (!token) {
      return next('/login')
    }
    return next()
})

export default router