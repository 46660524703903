import Vue from 'vue';
import App from './App.vue';
import router from '@/router'
import store from '@/store/index'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import http from 'axios';

Vue.use(ElementUI);
Vue.prototype.$http = http

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app');